<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  BellIcon,
  BellOffIcon,
} from "vue-feather-icons";
// import { Slide } from "vue-carousel";
// import { Carousel } from "vue-carousel";
import { VueTyper } from "vue-typer";
import Multiselect from "vue-multiselect";
import IndexService from "@/MainServices/IndexService.js";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import GeneralService from "@/MainServices/GeneralService.js";
import Skeleton from "@/view/front/components/skeleton";

/**
 * Index-job component
 */
export default {
  data() {
    return {
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      token:
        localStorage.getItem("token") !== null
          ? `Bearer ${localStorage.getItem("token")}`
          : null,
      data: [],
      search_text: null,
      selected_cities: null,
      cities: [],
      originalData: [],
      show_load_more: true,
      page_num: 1,
      sectorsData: [],
      featureData: [],
      spotlightData: [],
      featureCompaniesData: [],
      descriptionSearch: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    // Carousel,
    // Slide,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    ArrowUpIcon,
    "vue-typer": VueTyper,
    Multiselect,
    Skeleton,
    BellIcon,
    BellOffIcon,
  },
  mounted() {
    IndexService.getCities().then((res) => {
      res.data.map((el) => {
        this.cities.push({
          id: el.id,
          value: el.city_name,
        });
      });
      setTimeout(() => {
        document.querySelector(".temp1").textContent =
          document.querySelector(".temp").textContent.slice(0, 100) + "...";
        document.querySelector(".temp2").textContent =
          document.querySelector(".temp3").textContent.slice(0, 100) + "...";
      }, 1000);
    });
    this.getSectors();
    this.getFeatureJobs();
    this.getSpotlightJobs();
    this.getFeatureCompanies();
  },
  methods: {
    _descriptionSearch(event) {
      this.descriptionSearch = event.target.checked;
    },
    search() {
      this.$router.push({
        name: "search",
        params: {
          search_text: this.search_text,
          selected_cities: this.selected_cities,
          description_search: this.descriptionSearch,
        },
      });
    },
    getSectors() {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.sectorsData = response.data;
        this.loading = false;
      });
    },
    getFeatureJobs() {
      axios.get(`${this.apiUrl}/get-feature-jobs`).then((response) => {
        this.featureData = response.data;
      });
    },
    getSpotlightJobs() {
      axios.get(`${this.apiUrl}/get-spotlight-jobs`).then((response) => {
        this.spotlightData = response.data;
      });
    },
    getUrgentJobs() {
      axios.get(`${this.apiUrl}/get-urgent-jobs`).then((response) => {
        this.urgentData = response.data;
      });
    },
    getFeatureCompanies() {
      if (this.token !== null) {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .get(`${this.apiUrl}/get-feature-companies-auth`)
          .then((response) => {
            this.featureCompaniesData = response.data;
          });
      } else {
        axios.get(`${this.apiUrl}/get-feature-companies`).then((response) => {
          this.featureCompaniesData = response.data;
        });
      }
    },
    subscribe(item) {
      axios
        .get(`${this.apiUrl}/company-followup/${item.id}`)
        .then((response) => {
          this.getFeatureCompanies();
          alert(response.data.msg);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <Navbar />
    <!-- Hero Start -->
    <section class="bg-half-170 pb-0 bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="title-heading text-center mt-4 mt-md-5 pt-md-5">
              <div class="alert alert-light alert-pills" role="alert">
                <span class="badge badge-pill badge-success mr-1">Start</span>
                <span class="content">
                  your
                  <span class="text-primary">career</span>
                  now !</span
                >
              </div>
              <h1 class="heading mb-3">
                Job Vacancies for
                <vue-typer
                  :text="[
                    'Freelancer',
                    'Developer',
                    'Designer',
                    'UX Developer',
                    'ios Developer',
                  ]"
                ></vue-typer>
              </h1>
              <p class="para-desc mx-auto">
                Taking recruitment to the next level in Pakistan
              </p>
              <div class="rounded p-4 mt-4 bg-white">
                <div class="row text-left">
                  <div class="col-lg-5 col-md-4">
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        v-model="search_text"
                        class="form-control"
                        required
                        placeholder="Keywords"
                        @keyup.enter="search()"
                      />
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-7 col-md-8">
                    <div class="row align-items-center">
                      <div class="col-md-8 mt-4 mt-sm-0">
                        <div class="form-group mb-0">
                          <multiselect
                            id="multiselectCities"
                            v-model="selected_cities"
                            :height="300"
                            :options="cities"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Select Cities"
                            label="value"
                            track-by="value"
                            :preselect-first="false"
                          >
                          </multiselect>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-4 mt-4 mt-sm-0">
                        <button
                          @click="search()"
                          id="search"
                          name="search"
                          class="searchbtn btn btn-primary btn-block p"
                        >
                          Search
                        </button>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </div>
                  <!---end col-->

                  <div
                    class="form-group mb-0"
                    style="margin-left: 3%; margin-top: 2.5%"
                  >
                    <input
                      type="checkbox"
                      id="description_search"
                      name="description_search"
                      class="custom-control-input"
                      value="Yes"
                      v-on:click="_descriptionSearch($event)"
                    />
                    <label
                      class="custom-control-label"
                      for="description_search"
                      style="font-size: 16px"
                      >Description search</label
                    >
                  </div>
                </div>
                <!--end row-->
              </div>
              <p class="text-muted mb-0 mt-4">
                <span class="text-dark">Keywords :</span> Web Developer, Web
                Designer, Php Developer, IOS Developer, etc...
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-12 mt-4 pt-3">
            <img src="/images/job/home.png" class="img-fluid" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Job Sectors</h4>
              <!-- <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <Skeleton v-if="loading" />

        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
            v-for="(item, index) in sectorsData"
            :key="index"
          >
            <router-link
              :to="
                '/jobs-sector/' +
                item.job_function_name
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')
              "
              class="title h6 text-dark"
            >
              <div class="card catagories overflow-hidden rounded border-0">
                <img src="/images/blue_bg.jpeg" class="img-fluid" alt="" />
                <div class="card-body">
                  <ul class="list-unstyled d-flex justify-content-between mb-0">
                    <li>
                      <router-link
                        :to="
                          '/jobs-sector/' +
                          item.job_function_name
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        class="title h6 text-dark"
                        >{{ item.job_function_name }}</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs">
                      {{ item.count }}{{ item.count > 100 ? "+" : "" }} Jobs
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </div>

          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <!-- Companies Start -->
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Featured Jobs</h4>
              <!-- <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
            v-for="(item, index) in featureData"
            :key="index"
          >
            <router-link
              :to="
                '/jobs-careers/pakistan/' +
                item.location_city.map((v) => v.toLowerCase()).join('-') +
                '/' +
                item.job_title
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')+'/' +item.id
              "
              class="text-dark"
            >
              <div
                class="media key-feature align-items-center p-3 shadow rounded"
              >
                <img
                  :src="mediaUrl + item.recruiter.profile_pic"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
                <div class="media-body ml-3">
                  <div style="display: flex">
                    <h4 class="title mb-0 text-dark _bold">
                      {{ item.job_title }}
                    </h4>
                    <h6 class="_bold" style="margin-top: 0.6%; margin-left: 2%">
                      | {{ item.job_type.toString() }}
                    </h6>
                  </div>
                  <p class="text-muted mb-0 pix12">
                    {{ item.location_city.toString() }} | Rs.{{
                      item.salary_min
                    }}
                    - Rs.{{ item.salary_max }}
                  </p>
                  <p
                    style="display: none"
                    class="para-desc mx-auto text-muted temp pix12"
                    v-html="item && item.job_description"
                  ></p>

                  <p
                    class="para-desc mx-auto text-muted temp1 pix12"
                    style="margin-top: 2%; min-height: 55px"
                  ></p>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-12 text-center mt-4 pt-2">
            <router-link to="/jobs" class="btn btn-primary"
              >See All Jobs <i class="mdi mdi-chevron-right"></i
            ></router-link>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!-- Companies End -->

      <!-- Candidates Start -->
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Featured Companies</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-3 col-md-6 mt-4 pt-2"
            v-for="(item, index) in featureCompaniesData"
            :key="index"
          >
            <div class="card team text-center border-0">
              <div class="card-body job_tiles_ch">
                <div class="position-relative">
                  <img
                    :src="mediaUrl + item.profile_pic"
                    class="img-fluid avatar avatar-ex-large rounded-circle"
                    alt=""
                  />
                </div>
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <!-- <a href="javascript:void(0)" class="name text-dark">{{
                      item.company_name
                    }}</a> -->

                    <router-link
                      :to="
                        '/employer/' +
                        item.company_name
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')
                      "
                      class="text-dark company-name"
                      >{{ item && item.company_name }}</router-link
                    >
                  </h5>
                  <small class="designation text-muted">{{
                    item.branches[0].branchAddress
                  }}</small>
                  <ul class="list-unstyled social-icon team-icon mb-0">
                    <li
                      class="list-inline-item"
                      v-if="item.facebook && item.facebook.length > 0"
                    >
                      <a target="_blank" :href="item.facebook" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.instagram && item.instagram.length > 0"
                    >
                      <a target="_blank" :href="item.instagram" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.twitter && item.twitter.length > 0"
                    >
                      <a target="_blank" :href="item.twitter" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.linkedin && item.linkedin.length > 0"
                    >
                      <a target="_blank" :href="item.linkedin" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>

                    <li
                      class="list-inline-item ml-1"
                      v-if="item.subscribe == 1"
                    >
                      <a
                        @click.prevent="subscribe(item)"
                        class="rounded"
                        style="cursor: pointer"
                      >
                        <bell-off-icon
                          class="fea icon-sm fea-social"
                        ></bell-off-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.subscribe == 0"
                    >
                      <a
                        @click.prevent="subscribe(item)"
                        class="rounded"
                        style="cursor: pointer"
                      >
                        <bell-icon class="fea icon-sm fea-social"></bell-icon>
                      </a>
                    </li>
                  </ul>
                  <!--end icon-->
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!-- Candidates End -->

      <div
        class="container mt-100 mt-60"
        style="
          background: url(http://historic.job4u.pk/htmlcss/images/spotlight_bg.jpg)
            no-repeat center center;
          background-size: cover;
          padding-top: 50px;
          padding-bottom: 60px;
        "
      >
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4
                class="heading-inverse align-center"
                style="
                  text-transform: uppercase;
                  background: url(http://historic.job4u.pk/htmlcss/images/hr-inverse.png);
                  padding-bottom: 48px;
                  color: rgb(255, 255, 255);
                  background-repeat: inherit;
                  background-position-x: center;
                  background-position-y: center;
                "
              >
                Spotlight Jobs in Pakistan
              </h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
            v-for="(item, index) in spotlightData"
            :key="index"
          >
            <router-link
              :to="
                '/jobs-careers/pakistan/' +
                item.location_city.map((v) => v.toLowerCase()).join('-') +
                '/' +
                item.job_title
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')+'/' +item.id
              "
              class="text-dark"
            >
              <div
                class="media key-feature align-items-center p-3 rounded"
                style="background: white"
              >
                <img
                  :src="mediaUrl + item.recruiter.profile_pic"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
                <div class="media-body ml-3">
                  <div style="display: flex">
                    <h4 class="title mb-0 text-dark _bold">
                      {{ item.job_title }}
                    </h4>
                    <h6 class="_bold" style="margin-top: 0.6%; margin-left: 2%">
                      | {{ item.job_type.toString() }}
                    </h6>
                  </div>
                  <p class="text-muted mb-0 pix12">
                    {{ item.location_city.toString() }} | Rs.{{
                      item.salary_min
                    }}
                    - Rs.{{ item.salary_max }}
                  </p>
                  <p
                    style="display: none"
                    class="para-desc mx-auto text-muted temp3 pix12"
                    v-html="item && item.job_description"
                  ></p>

                  <p
                    class="para-desc mx-auto text-muted temp2 pix12"
                    style="margin-top: 2%; min-height: 55px"
                  ></p>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-12 text-center mt-4 pt-2">
            <router-link to="/jobs" class="btn btn-primary"
              >See All Jobs <i class="mdi mdi-chevron-right"></i
            ></router-link>
          </div>
        </div>
        <!--end row-->
      </div>

      <div
        class="container mt-100 mt-60"
        style="
          background: url(http://historic.job4u.pk/htmlcss/images/spotlight_bg.jpg)
            no-repeat center center;
          background-size: cover;
          padding-top: 50px;
          padding-bottom: 60px;
        "
      >
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4
                class="heading-inverse align-center"
                style="
                  text-transform: uppercase;
                  background: url(http://historic.job4u.pk/htmlcss/images/hr-inverse.png);
                  padding-bottom: 48px;
                  color: rgb(255, 255, 255);
                  background-repeat: inherit;
                  background-position-x: center;
                  background-position-y: center;
                "
              >
                Urgent Jobs in Pakistan
              </h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
            v-for="(item, index) in urgentData"
            :key="index"
          >
            <router-link
              :to="
                '/jobs-careers/pakistan/' +
                item.location_city.map((v) => v.toLowerCase()).join('-') +
                '/' +
                item.job_title
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')+'/' +item.id
              "
              class="text-dark"
            >
              <div
                class="media key-feature align-items-center p-3 rounded"
                style="background: white"
              >
                <img
                  :src="mediaUrl + item.recruiter.profile_pic"
                  class="avatar avatar-ex-sm"
                  alt=""
                />
                <div class="media-body ml-3">
                  <div style="display: flex">
                    <h4 class="title mb-0 text-dark _bold">
                      {{ item.job_title }}
                    </h4>
                    <h6 class="_bold" style="margin-top: 0.6%; margin-left: 2%">
                      | {{ item.job_type.toString() }}
                    </h6>
                  </div>
                  <p class="text-muted mb-0 pix12">
                    {{ item.location_city.toString() }} | Rs.{{
                      item.salary_min
                    }}
                    - Rs.{{ item.salary_max }}
                  </p>
                  <p
                    style="display: none"
                    class="para-desc mx-auto text-muted temp3 pix12"
                    v-html="item && item.job_description"
                  ></p>

                  <p
                    class="para-desc mx-auto text-muted temp2 pix12"
                    style="margin-top: 2%; min-height: 55px"
                  ></p>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-12 text-center mt-4 pt-2">
            <router-link to="/jobs" class="btn btn-primary"
              >See All Jobs <i class="mdi mdi-chevron-right"></i
            ></router-link>
          </div>
        </div>
        <!--end row-->
      </div>

      <!-- start ch -->
      <div
        class="row join-us"
        style="
          background: url(images/section_bgch.jpg) no-repeat center center;
          margin-top: 4em;
          margin-bottom: 4em;
        "
      >
        <div class="container join chchch">
          <div class="col-sm-3">
            <h1 class="fontch1">Join Our<br />Employement<br />Network</h1>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2">Apply to Jobs faster even from mobile</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2">
                  Post a Resume to be found by Registered Empoyers
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2 hpch">Get response alert for free</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end ch -->
      <div class="container" style="margin-top: 5%">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Browse Local Jobs</h4>
              <!-- <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p> -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Lahore"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >LAHORE</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/punjab.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li class="h6 mb-0 jobs"><b>Jobs in Punjab</b></li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Lahore"
                        class="title h6 text-dark"
                        >Jobs in Lahore</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sialkot"
                        class="title h6 text-dark"
                        >Jobs in Sialkot</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gujranwala"
                        class="title h6 text-dark"
                        >Jobs in Gujranwala</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Faisalabad"
                        class="title h6 text-dark"
                        >Jobs in Faisalabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Rawalpindi"
                        class="title h6 text-dark"
                        >Jobs in Rawalpindi</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Karachi"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >KARACHI</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/sindh.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li class="h6 mb-0 jobs"><b>Jobs in Sindh</b></li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Karachi"
                        class="title h6 text-dark"
                        >Jobs in Karachi</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Hyderabad"
                        class="title h6 text-dark"
                        >Jobs in Hyderabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sakhar"
                        class="title h6 text-dark"
                        >Jobs in Sakhar</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Dadu"
                        class="title h6 text-dark"
                        >Jobs in Dadu</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Larkana"
                        class="title h6 text-dark"
                        >Jobs in Larkana</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Peshawer"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >PESHAWER</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/kpk.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li class="h6 mb-0 jobs"><b>Jobs in KPK</b></li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Peshawer"
                        class="title h6 text-dark"
                        >Jobs in Peshawer</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Abbotabad"
                        class="title h6 text-dark"
                        >Jobs in Abbotabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Swabi"
                        class="title h6 text-dark"
                        >Jobs in Swabi</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Kohat"
                        class="title h6 text-dark"
                        >Jobs in Kohat</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/DI Khan"
                        class="title h6 text-dark"
                        >Jobs in DI Khan</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Peshawer"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >View All</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Quetta"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >QUETTA</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/balochistan.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li class="h6 mb-0 jobs"><b>Jobs in Balochistan</b></li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Quetta"
                        class="title h6 text-dark"
                        >Jobs in Quetta</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gawader"
                        class="title h6 text-dark"
                        >Jobs in Gawader</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Ziarat"
                        class="title h6 text-dark"
                        >Jobs in Ziarat</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Chaman"
                        class="title h6 text-dark"
                        >Jobs in Chaman</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sui"
                        class="title h6 text-dark"
                        >Jobs in Sui</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Mirpur"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >MIRPUR</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/kashmir.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li class="h6 mb-0 jobs"><b>Jobs in Kashmir</b></li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Muzafrabad"
                        class="title h6 text-dark"
                        >Jobs in Muzafrabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Mirpur"
                        class="title h6 text-dark"
                        >Jobs in Mirpur</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Kotli"
                        class="title h6 text-dark"
                        >Jobs in Kotli</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Bagh"
                        class="title h6 text-dark"
                        >Jobs in Bagh</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Haveli"
                        class="title h6 text-dark"
                        >Jobs in Haveli</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Gilgit"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >GILGIT</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/baltistan.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li class="h6 mb-0 jobs">
                      <b>Jobs in Gilgit Baltistan</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gilgit"
                        class="title h6 text-dark"
                        >Jobs in Gilgit</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Skardu"
                        class="title h6 text-dark"
                        >Jobs in Skardu</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Hunza"
                        class="title h6 text-dark"
                        >Jobs in Hunza</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Nagar"
                        class="title h6 text-dark"
                        >Jobs in Nagar</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--end col-->
        </div>
        <!--end row-->
      </div>

      <!-- Testi start -->
      <!-- <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Candidates Review</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-4 pt-2">
            <carousel
              id="customer-testi"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="3"
            >
              <Slide>
                <div class="card customer-testi border-0 text-center">
                  <div class="card-body">
                    <img
                      src="images/client/01.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " It seems that only fragments of the original text remain
                      in the Lorem Ipsum texts used today. "
                    </p>
                    <h6 class="text-primary">- Thomas Israel</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div class="card customer-testi border-0 text-center">
                  <div class="card-body">
                    <img
                      src="images/client/02.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " The most well-known dummy text is the 'Lorem Ipsum',
                      which is said to have originated in the 16th century. "
                    </p>
                    <h6 class="text-primary">- Carl Oliver</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div class="card customer-testi border-0 text-center">
                  <div class="card-body">
                    <img
                      src="images/client/03.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " One disadvantage of Lorum Ipsum is that in Latin certain
                      letters appear more frequently than others. "
                    </p>
                    <h6 class="text-primary">- Barbara McIntosh</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div class="card customer-testi border-0 text-center">
                  <div class="card-body">
                    <img
                      src="images/client/04.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " Thus, Lorem Ipsum has only limited suitability as a
                      visual filler for German texts. "
                    </p>
                    <h6 class="text-primary">- Jill Webb</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div class="card customer-testi border-0 text-center">
                  <div class="card-body">
                    <img
                      src="images/client/05.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " There is now an abundance of readable dummy texts. These
                      are usually used when a text is required. "
                    </p>
                    <h6 class="text-primary">- Dean Tolle</h6>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div class="card customer-testi border-0 text-center">
                  <div class="card-body">
                    <img
                      src="images/client/06.jpg"
                      class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt=""
                    />
                    <p class="text-muted mt-4">
                      " According to most sources, Lorum Ipsum can be traced
                      back to a text composed by Cicero. "
                    </p>
                    <h6 class="text-primary">- Christa Smith</h6>
                  </div>
                </div>
              </Slide>
            </carousel>
          </div>
        </div>
      </div> -->
      <!--end container-->

      <!--end container-->
      <!-- Testi end -->
    </section>
    <!--end section-->
    <!-- End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-light">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.vue-typer .custom.char.typed {
  color: #2f55d4;
}
</style>
